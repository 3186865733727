import React, { useContext } from 'react';
import { useRouter } from 'next/router';
import { IFeatures, FeaturesContext } from 'contexts/features';
import Link from 'components/link';
import ImageLoader from 'components/image-loader';
import TwitterIcon from 'components/icon/twitter-icon';
import FacebookIcon from 'components/icon/facebook-icon';
import InstagramIcon from 'components/icon/instagram-icon';
import LinkedInIcon from 'components/icon/linkedin-icon';
import MailIcon from 'components/icon/mail-icon';
import PhoneIcon from 'components/icon/phone-icon';
import styles from './zoocasaFooterStyle.module.scss';
import { useUser } from 'contexts';
import Feedbackify from 'components/feedbackify';
import { buildClassName } from 'utils/build-class-name';
import { useThemeContext } from 'contexts/theme';
import { ThemeConfig } from 'themes';
import Button, { SECONDARY_THEME } from 'components/control/button';

export default function ZoocasaFooter() {
  const { features } = useContext(FeaturesContext) as IFeatures;
  const { theme } = useThemeContext();
  const { siteLocation } = useUser();
  const router = useRouter();
  const isActive = router.route.match(/^((?!\/team\/|\/search).)*$/);
  const isOnPreConPage = router.pathname.includes('/newhomes');
  const address = theme.organization.address(siteLocation);

  const featureFlags = () => {
    if (features['footerFeatures']) {
      return Object.keys(features).map(key => {
        if (Object.prototype.hasOwnProperty.call(features, key)) {
          return <p key={key} className={styles['feature-flags']}>{key}: {(features as unknown as Record<string, string>)[key].toString()}</p>;
        }
      });
    }
  };

  const featureUI = <div className={styles.features}>{featureFlags()}</div>;

  const expLogo = <div className={styles['exp-logo-container']}>
    <p>Owned by</p>
    <div className={styles['exp-logo']}>
      <a href="https://exprealty.com/" target="_blank" rel="noreferrer">
        <ImageLoader src="/next/assets/images/exp-realty-logo.svg" alt="eXp Realty"/>
      </a>
    </div>
  </div>;

  return !isActive ? null :
    <div className={styles.component}>
      <div className={styles['footer-top']}>
        <div className={styles['footer-top-left']}>
          <div className={styles['footer-links']}>
            <div className={buildClassName(styles.block, styles.block1)}>
              <h3 className={styles.heading}>Company</h3>
              <TermsAndConditionsAndContactLinks />
            </div>
            <div className={buildClassName(styles.block, styles.block2)}>
              <h3 className={styles.heading}>Stay Connected</h3>
              <EmailPhoneNumberContacts isOnPreConPage={isOnPreConPage} theme={theme} />
              <p className={styles['office-address']}>
                {address}
              </p>
              <div className={styles.socials}>
                <a href={theme.organization.twitterUrl} target="_blank" rel="noreferrer"><TwitterIcon /></a>
                <a href={theme.organization.facebookUrl} target="_blank" rel="noreferrer"><FacebookIcon className={styles.invert} /></a>
                <a href={theme.organization.instagramUrl} target="_blank" rel="noreferrer"><InstagramIcon className={styles.invert} /></a>
                <a href={theme.organization.linkedinUrl} target="_blank" rel="noreferrer"><LinkedInIcon className={styles.invert} /></a>
              </div>
            </div>
            <div className={buildClassName(styles.block, styles.block3)}>
              <h3 className={styles.heading}>Download Our App</h3>
              <AppStoreLinks />
            </div>
            <div className={buildClassName(styles.block, styles.block4)}>
              <Feedbackify className={styles['feedback-button']}/>
              <div className={styles['hide-mobile']}>{expLogo}</div>
              <div className={styles['hide-mobile']}>
                <ExpDynamicCopyright/>
              </div>
              <div className={styles['hide-desktop']}>{expLogo}</div>
              <div className={styles['hide-desktop']}><ExpDynamicCopyright/></div>
            </div>
          </div>
          <div className={styles['footer-big-text']}>
            <ImageLoader className={styles['zoocasa-logo']} src={'/next/assets/images/zoocasa-footer-logo-white-large.svg'} alt={'Zoocasa Logo'}/>
            <div className={buildClassName(styles['join-section-mobile-container'])}>
              <JoinSection joinUsLink={theme?.footerContent?.joinUsLink} footerImageData={theme?.footerContent?.footerImageData} />
            </div>
          </div>
        </div>
        <div className={buildClassName(styles['footer-top-right'])}>
          <JoinSection joinUsLink={theme?.footerContent?.joinUsLink} footerImageData={theme?.footerContent?.footerImageData}/>
        </div>
      </div>
      <div className={buildClassName(styles['footer-bottom'])}>
        <div className={styles.wrap}><p>The trademarks MLS®, Multiple Listing Service® and the associated logos are owned by The Canadian Real Estate Association (CREA) and identify the quality of services provided by real estate professionals who are members of CREA.</p></div>
        <p>eXp Realty holds real estate brokerage licenses in multiple states. Zoocasa (Canada), Inc. holds real estate brokerage licenses in multiple provinces. For information on licences please contact us at info@zoocasa.com</p>
        <p>For listings in Canada, the trademarks REALTOR®, REALTORS®, and the REALTOR® logo are controlled by The Canadian Real Estate Association (CREA) and identify real estate professionals who are members of CREA. The trademarks MLS®, Multiple Listing Service® and the associated logos are owned by CREA and identify the quality of services provided by real estate professionals who are members of CREA. Used under license.</p>
        <p>MLS® Compliance Statements<br/>
          Tony King &ndash; Designated Managing Broker, Broker Operations &ndash; TX.&nbsp;
          <Link target="_blank" href="https://drive.google.com/file/d/1oAApwK-x8v9_aWc_h9eEd9jQWjlZJs99/view?pli=1">Texas Real Estate Commission information about brokerage services</Link><br/>
          <Link target="_blank" href="https://www.trec.texas.gov/sites/default/files/pdf-forms/CN%201-5_0.pdf">Texas Real Estate Commission Consumer Protection Notice</Link>
        </p>
        <p>
          eXp Realty® is committed to adhering to the guidelines of The New York State Fair Housing Regulations. To view The Fair Housing Notice -&nbsp;
          <Link target="_blank" href="https://dos.ny.gov/system/files/documents/2021/08/fairhousingnotice.pdf">Please Click Here</Link>.
          Standardized Operating Procedure for Purchasers of Real Estate Pursuant to Real Property Law 442-H. To View -&nbsp;
          <Link target="_blank" href="https://docs.google.com/document/d/1VPtYQJszaH0PA0HU5D4oB3A4piNbyKXSRKwBKvGQeIU/edit">Please Click Here</Link>.
        </p>
        <p>
          eXp Realty of California, Inc. | CA DRE# 01878277 | Deborah Penny &ndash; Designated Broker<br/>
          eXp Realty of Southern California, Inc. | CA DRE# 02187306 | Gina Saad &ndash; Designated Broker<br/>
          eXp Realty of Greater Los Angeles, Inc. | CA DRE# 02188471 | Anita Cruz &ndash; Designated Broker<br/>
          eXp Realty of Northern California, Inc. | CA DRE# 01951343 | Ryan Rosas Penny &ndash; Designated Broker<br/>
          <Link href="https://exprealty.com/fair-housing-and-reasonable-accommodations/">Fair Housing and Reasonable Accomodations</Link><br/>
          <Link href="https://exprealty.com/dmca/" >DMCA Notice</Link><br/>
        </p>
      </div>
      {featureUI}
    </div>;
}

const ExpDynamicCopyright = () => <div className={styles['exp-copyright']}><p> © {new Date().getFullYear()} eXp Realty. eXp World Holdings, Inc. All Rights Reserved</p></div>;

const EmailPhoneNumberContacts = ({ isOnPreConPage, theme }: { isOnPreConPage: boolean; theme: ThemeConfig }) => {
  const phoneNumber = theme.organization.phoneNumber(isOnPreConPage);
  const emailAddress = theme.organization.email(isOnPreConPage);

  return (
    <section className={styles.contact}>
      {phoneNumber && (
        <div className={styles['contact-item']}>
          <a href={`tel:${phoneNumber}`}>
            <PhoneIcon />
            <p>
              {phoneNumber}
            </p>
          </a>
        </div>
      )}
      {emailAddress && (
        <div className={styles['contact-item']}>
          <a href={`mailto:${emailAddress}`}>
            <MailIcon />
            <p>
              {emailAddress}
            </p>
          </a>
        </div>
      )}
    </section>
  );
};

const TermsAndConditionsAndContactLinks = () => {
  return (
    <div className={styles['terms-and-conditions']}>
      <div>
        <Link href="/terms-of-use" target="_blank">Terms of Use</Link>
        <Link href="/privacy-policy" target="_blank">Privacy Policy</Link>
        <a href="https://careers.zoocasa.com">Careers</a>
        <Link href="/company/our-story">About Us</Link>
        <Link href="/sitemap">Sitemap</Link>
      </div>
    </div>
  );
};

const AppStoreLinks = () => {
  return (
    <>
      <div className={styles['app-store-links']}>
        <a href="http://itunes.apple.com/WebObjects/MZStore.woa/wa/viewSoftware?id=301743811&mt=8" target="_blank" rel="noreferrer">
          <ImageLoader src="/next/assets/images/appleappstore.svg" alt="Apple Store" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.zoocasareactnative" target="_blank" rel="noreferrer">
          <ImageLoader src="/next/assets/images/googleplay.svg" alt="Google Play Store" />
        </a>
      </div>
      <LinksToUSAndCAListingPages />
    </>
  );
};

const JoinSection = ({ joinUsLink, footerImageData }) => {
  return (
    <div className={styles['join-section']}>
      <div className={styles['join-image-container']}>
        <ImageLoader src={footerImageData.src} alt={footerImageData.alt}/>
      </div>
      <Link href={joinUsLink} target='_blank' className={styles['join-link']}>
        <Button theme={SECONDARY_THEME} label={'Find Your Home'} className={styles['join-button']} />
      </Link>
    </div>
  );
};


const LinksToUSAndCAListingPages = () => {
  return (
    <div className={styles['listing-links']}>
      <Link href="https://www.zoocasa.com/us-real-estate">US Real Estate Listings</Link>
      <Link href="https://www.zoocasa.com/ca-real-estate">Canada Real Estate Listings</Link>
    </div>
  );
};
