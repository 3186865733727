import configJSON from 'config.json';
import Cookies from 'js-cookie';
import { getJSON } from './cookies/request-cookies';
import params from 'jquery-param';
import { camelizeKeys, underscoreKeys, dasherizeKeys } from '@zoocasa/node-kit/objects/transform-keys';
import { fetchWithRetry } from './fetchWithRetry';
import { RetryOnStatusOptions } from 'types/fetchWithRetry';
import { HttpStatusCode } from 'types/HttpStatusCode';
import { X_ZOOCASA_GENERATION_HEADER_NAME, X_ZOOCASA_REQUEST_SOURCE_HEADER_NAME } from 'constants/headers';

import type { User } from 'contexts/user';
import { MLML_LEAD_COOKIE_NAME } from 'constants/cookies';

export type ErrorObjects = {
  errors: {
    source?: {
      pointer?: string;
    };
    title?: string;
  }[];
};

export interface ErrorResponse {
  errors: ErrorObjects;
  data: never[];
  nestedPlaces: never[];
  meta: {
    totalPages: number;
    totalCount: number;
    pageNumber: number;
    pageSize: number;
  };
}

// This is a type guard to check if the response is an error response
export function isErrorResponse(response: any): response is ErrorResponse {
  return response && response.errors && Array.isArray(response.errors);
}

const RETRY_ON_STATUS: RetryOnStatusOptions = { statusCodes: [
  HttpStatusCode.TOO_MANY_REQUESTS,
  HttpStatusCode.BAD_GATEWAY,
  HttpStatusCode.GATEWAY_TIMEOUT,
  HttpStatusCode.SERVICE_UNAVAILABLE,
]} as const;

export default async function endpoint<T>(url: string, method = 'GET', data?: Record<string, unknown>, headers = apiHeaders(), signal?: AbortSignal) {
  if (!url.match(/^http/)) {
    url = configJSON.host + url;
  }
  const options: Record<string, unknown> = {
    method: method,
    headers,
    signal,
  };

  if (data) {
    data = transformDataKeys(url, data);
    if (method === 'GET') {
      url = addDataToUrl(url, data);
    } else {
      options.body = JSON.stringify(data);
    }
  }
  const response = await fetchWithRetry(url, options, RETRY_ON_STATUS);
  if (response.status === 204) {
    return promise as unknown as Promise<T>;
  } else {
    return response.json()
      .then(promise)
      .catch(err => {
      // Check if the error is already an ErrorResponse object
        if (isErrorResponse(err)) { 
          return Promise.reject(err); // Reject directly if it is
        }

        if (!err?.errors) {
          const error = err?.errors || err;
          const errorMessage = `Status: [${response.status}] ${response.statusText}, Error: ${JSON.stringify(error)}`;
          console.error(errorMessage);
        }
        const errorResponse: ErrorResponse = {
          errors: err?.errors || [],
          data: [],
          nestedPlaces: [],
          meta: { totalPages: 0, totalCount: 0, pageNumber: 1, pageSize: 24 },
        };
        return errorResponse;
      }) as Promise<T>;
  }
}

function transformDataKeys(url: string, data: Record<string, unknown>) {
  data = Object.assign({}, data);
  if (url.match(/api\/v2\//)) {
    data = underscoreKeys(data);
  } else if (url.match(/api\/v[1,3]\//)) {
    data = dasherizeKeys(data);
  }
  return data;
}

export function apiHeaders() {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    [X_ZOOCASA_REQUEST_SOURCE_HEADER_NAME]: 'zoocasa.com',
    [X_ZOOCASA_GENERATION_HEADER_NAME]: 'next',
  };
  const user = getJSON(Cookies.get('user')) as User | null;
  if (user && user.jwt) {
    headers['Authorization'] = `Bearer ${user.jwt}`;
  }
  const cookieValue = Cookies.get(MLML_LEAD_COOKIE_NAME);
  if (cookieValue) {
    headers['x-zoocasa-mlml'] = cookieValue;
  }
  return headers;
}

function addDataToUrl(url: string, data: Record<string, unknown>) {
  data = Object.assign({}, data);
  const values = Object.keys(data).map(key => data[key]).filter(i => i);
  if (values.length) {
    url += '?' + params(data);
  }
  return url;
}

function promise(response: Record<string, unknown>) {
  return new Promise((resolve, reject) => {
    if (!(response instanceof Array)) {
      response = camelizeKeys(response);
    }
    if (response.errors) {
      reject(response);
    } else {
      resolve(response);
    }
  });
}
