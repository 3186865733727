import React, { useContext } from 'react';
import { useRouter } from 'next/router';
import { FeaturesContext, IFeatures } from 'contexts/features';
import Link from 'components/link';
import ImageLoader from 'components/image-loader';
import TwitterIcon from 'components/icon/twitter-icon';
import FacebookIcon from 'components/icon/facebook-icon';
import InstagramIcon from 'components/icon/instagram-icon';
import LinkedInIcon from 'components/icon/linkedin-icon';
import MailIcon from 'components/icon/mail-icon';
import PhoneIcon from 'components/icon/phone-icon';
import YoutubeIcon from 'components/icon/youtube-icon';
import styles from './expRealtyFooterStyle.module.scss';
import { useThemeContext } from 'contexts/theme';
import { useUser } from 'contexts';
import { buildClassName } from 'utils/build-class-name';
import { ThemeConfig } from 'themes';
import Button, { SECONDARY_THEME } from 'components/control/button';
import { ThemeNames } from 'types/themes';

export default function ExpRealtyFooter() {
  const { features } = useContext(FeaturesContext) as IFeatures;
  const { theme, themeName } = useThemeContext();
  const { siteLocation } = useUser();
  const router = useRouter();
  const isActive = router.route.match(/^((?!\/team\/|\/search).)*$/);
  const address = theme.organization.address(siteLocation);

  const featureFlags = () => {
    if (features['footerFeatures']) {
      return Object.keys(features).map(key => {
        if (Object.prototype.hasOwnProperty.call(features, key)) {
          return <p key={key} className={styles['feature-flags']}>{key}: {(features as unknown as Record<string, string>)[key].toString()}</p>;
        }
      });
    }
  };

  const featureUI = <div className={styles.features}>{featureFlags()}</div>;

  return !isActive ? null :
    <div className={styles.component}>
      <div className={styles['footer-top']}>
        <div className={styles['footer-top-left']}>
          <div className={styles['footer-links']}>
            <div className={buildClassName(styles.block, styles.block1)}>
              <Column1 theme={theme} />
            </div>
            <div className={buildClassName(styles.block, styles.block2)}>
              <Column2 theme={theme} themeName={themeName}/>
            </div>
            <div className={buildClassName(styles.block, styles.block3)}>
              <Column3 theme={theme} />
            </div>
            <div className={buildClassName(styles.block, styles.block4)}>
              <p className={styles['office-address']}>
                {address}
              </p>
              <EmailPhoneNumberContacts theme={theme}/>
              <div className={styles.socials}>
                <a href={theme.organization.twitterUrl} target="_blank" rel="noreferrer"><TwitterIcon isExpTheme className={styles['social-x-icon']}/></a>
                <a href={theme.organization.facebookUrl} target="_blank" rel="noreferrer"><FacebookIcon isExpTheme className={styles['social-x-icon']}/></a>
                <a href={theme.organization.linkedinUrl} target="_blank" rel="noreferrer"><LinkedInIcon isExpTheme className={styles['social-x-icon']}/></a>
                <a href={theme.organization.instagramUrl} target="_blank" rel="noreferrer"><InstagramIcon isExpTheme className={styles['social-ig-icon']}/></a>
                <a href={theme.organization.youtubeUrl} target="_blank" rel="noreferrer"><YoutubeIcon className={styles['social-ig-icon']}/></a>
              </div>
            </div>
          </div>
          <div className={styles['footer-big-text']}>
            <p>
              eXp REALTY
            </p>
            <div className={buildClassName(styles['join-section-mobile-container'])}>
              <JoinSection joinUsLink={theme?.footerContent?.joinUsLink} footerImageData={theme?.footerContent?.footerImageData} />
            </div>
          </div>
        </div>
        <div className={buildClassName(styles['footer-top-right'])}>
          <JoinSection joinUsLink={theme?.footerContent?.joinUsLink} footerImageData={theme?.footerContent?.footerImageData}/>
        </div>
      </div>
      <div className={styles['footer-bottom']}>
        { theme?.footerContent?.bottom.map((section, index) => {
          return (
            <p key={index}>
              {section.map(({ isAnchor, link, target, label }, innerIndex) => {
                return (
                  <span key={innerIndex}>
                    {
                      isAnchor ?
                        <a key={index} href={link} target={target}>{label}</a>
                        :
                        <>{label}</>
                    }
                  </span>
                );
              })}
            </p>
          );
        })}
        <ExpDynamicCopyright/>
      </div>
      {featureUI}
    </div>;
}

const ExpDynamicCopyright = () =>
  <p> © {new Date().getFullYear()} eXp Realty. eXp World Holdings, Inc. All Rights Reserved</p>;

const handleAccessibilityClick = () => {
  const element = document.getElementById('INDmenu-btn');
  if (element) element.click();
};

const EmailPhoneNumberContacts = ({ theme }: { theme: ThemeConfig }) => {
  const phoneNumber = theme.organization.phoneNumber();
  const emailAddress = theme.organization.email();

  return (
    <section className={styles.contact}>
      {phoneNumber && (
        <div className={styles['contact-item']}>
          <a href={`tel:${phoneNumber}`}>
            <PhoneIcon/>
            <p>
              {phoneNumber}
            </p>
          </a>
        </div>
      )}
      {emailAddress && (
        <div className={styles['contact-item']}>
          <a href={`mailto:${emailAddress}`}>
            <MailIcon/>
            <p>
              {emailAddress}
            </p>
          </a>
        </div>
      )}
    </section>
  );
};

const Column1 = ({ theme }) => {
  return (
    <div className={styles['about-exp-links']}>
      {
        theme?.footerContent?.column1.map(({ isAnchor, link, target, label }, index) => {
          return (isAnchor ?
            <a key={`column1-${index}`} href={link} target={target}>{label}</a>
            :
            <Link key={`column1-${index}`} href={link} target={target}>{label}</Link>);
        })
      }
    </div>
  );
};

const Column2 = ({ theme, themeName }) => {
  return (
    <div className={styles['about-exp-links']}>
      {
        theme?.footerContent?.column2.map(({ isAnchor, link, target, label }, index) => {
          if (label === 'Accessibility' && themeName == ThemeNames.EXP_REALTY_US) {
            return (<a key={`column2-${index}`} onClick={handleAccessibilityClick}>Accessibility</a>);
          }
          return (isAnchor ?
            <a key={`column2-${index}`} href={link} target={target}>{label}</a>
            :
            <Link key={`column2-${index}`} href={link} target={target}>{label}</Link>);
        })
      }
      {themeName == ThemeNames.EXP_REALTY_CA && <a onClick={handleAccessibilityClick}>Accessibility</a>}
    </div>
  );
};
const Column3 = ({ theme }) => {
  return (
    <div className={buildClassName(styles['about-exp-links'], styles['terms-and-conditions'])}>
      {
        theme?.footerContent?.column3.map(({ isAnchor, link, target, label }, index) => {
          return (isAnchor ?
            <a key={`column3-${index}`} href={link} target={target}>{label}</a>
            :
            <Link key={`column3-${index}`} href={link} target={target}>{label}</Link>);
        })
      }
    </div>
  );
};
const JoinSection = ({ joinUsLink, footerImageData }) => {
  return (
    <div className={styles['join-section']}>
      <div className={styles['join-image-container']}>
        <ImageLoader src={footerImageData.src} alt={footerImageData.alt}/>
      </div>
      <Link href={joinUsLink} target='_blank' className={styles['join-link']}>
        <Button theme={SECONDARY_THEME} label={'Join Us'} className={styles['join-button']} />
      </Link>
    </div>
  );
};
