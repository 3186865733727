import { SITE_LOCATION_COOKIE_NAME } from 'constants/cookies';
import { IncomingMessage } from 'http';
import { CountryCode, CountryCodeList } from 'types/countries';
import { ThemeNames } from 'types/themes';
import { getStringFromRequestCookie } from './cookies';
import { FeaturesType } from 'contexts/features';
import isUserAgentCrawler from './crawler-agent';
import { USER_AGENT_HEADER_NAME } from 'constants/headers';
import { getFeaturesOverrideFromRequest } from 'utils/features';
import { getThemeOverrideFromRequest, themeNameOrDefault } from './themes';

/**
 * Retrieves the site location based on the incoming request.
 * 
 * The function determines the site location through several steps:
 * 1. Attempts to get the location from the site location cookie.
 * 2. Checks if the request is from a crawler user agent.
 * 3. Checks feature overrides from the request.
 * 4. Checks theme overrides from the request.
 * 
 * The function applies specific rules based on the detected conditions:
 * - If the user agent is a crawler, sets the location to Canada.
 * - If the theme is `EXP_REALTY_CA`, ensures the location is set to Canada.
 * - If the theme is `EXP_REALTY_US` and no location is found, sets the location to the United States.
 * - If the theme is `EXP_IN_SERVICE` and no location is found, sets the location to the United States.
 * - If the theme is `ZOOCASA` and no location is found, sets the location based on the user location.
 * 
 * If no location is determined through these steps, defaults to Canada.
 * 
 * @param request The incoming HTTP request object.
 * @param userCountryCode The user location country code.
 * @returns The country code representing the site location. Defaults to `CountryCodeList.CANADA`.
 */
export function getSiteLocationFromRequest(request?: IncomingMessage, userCountryCode = CountryCodeList.CANADA): CountryCode {
  const storedSiteLocation: CountryCode | undefined = getStringFromRequestCookie(SITE_LOCATION_COOKIE_NAME, request) as CountryCode;

  const isCrawler = isUserAgentCrawler(request?.headers);
  const features = getFeaturesOverrideFromRequest(request) as unknown as FeaturesType;
  const themeOverride = getThemeOverrideFromRequest(request);
  const themeName = themeNameOrDefault(themeOverride) as ThemeNames;

  if (isCrawler) {
    return CountryCodeList.CANADA;
  } else if (themeName === ThemeNames.EXP_REALTY_CA) {
    // Make sure that the site location is set to Canada if the theme is EXP_REALTY_CA
    // https://www.notion.so/Make-sure-Bedrock-exp-Canada-site-is-fully-defaulted-to-Canada-c4bdbcc8558641fc832d78f8d1f13114
    return CountryCodeList.CANADA;
  } else if (themeName === ThemeNames.EXP_REALTY_US) {
    return CountryCodeList.UNITED_STATES;
  } else if (themeName === ThemeNames.EXP_IN_SERVICE) {
    return CountryCodeList.UNITED_STATES;
  } else if (features.useUsLocation) {
    return CountryCodeList.UNITED_STATES;
  } else if (themeName === ThemeNames.ZOOCASA && !storedSiteLocation) {
    if (userCountryCode === CountryCodeList.CANADA) {
      return CountryCodeList.CANADA;
    } else if (userCountryCode === CountryCodeList.UNITED_STATES) {
      return CountryCodeList.UNITED_STATES;
    }
  }

  return storedSiteLocation || CountryCodeList.CANADA;
}
