import React, { useContext } from 'react';
import { useRouter } from 'next/router';
import { FeaturesContext, IFeatures, useFeaturesContext } from 'contexts/features';
import Link from 'components/link';
import TwitterIcon from 'components/icon/twitter-icon';
import FacebookIcon from 'components/icon/facebook-icon';
import InstagramIcon from 'components/icon/instagram-icon';
import LinkedInIcon from 'components/icon/linkedin-icon';
import MailIcon from 'components/icon/mail-icon';
import PhoneIcon from 'components/icon/phone-icon';
import YoutubeIcon from 'components/icon/youtube-icon';
import styles from './expThemedFooter.module.scss';
import { useUser } from 'contexts';
import { buildClassName } from 'utils/build-class-name';
import Button, { SECONDARY_THEME } from 'components/control/button';
import { useIsMobile } from 'hooks/use-size-class';
import type { ExpFooterComponentProps } from '../../tenants';
import ImageLoader from 'components/image-loader';

function ExpThemedFooter({ config }: ExpFooterComponentProps) {
  const { siteLocation } = useUser();
  const router = useRouter();
  const isActive = router.route.match(/^((?!\/team\/|\/search).)*$/);
  const address = config.organization.address(siteLocation);
  const isMobile = useIsMobile();

  return !isActive ? null :
    <div className={styles.component}>
      <div className={styles['footer-top']}>
        <div className={styles['footer-top-left']}>
          <div className={styles['footer-links']}>
            <div className={buildClassName(styles.block, styles.block1)}>
              <Column1 column1={config.footerColumns.column1} />
            </div>
            <div className={buildClassName(styles.block, styles.block2)}>
              <Column2 column2={config.footerColumns.column2} showAccessibilityLink={config.showAccessibilityLink} />
            </div>
            <div className={buildClassName(styles.block, styles.block3)}>
              <Column3 column3={config.footerColumns.column3}/>
            </div>
            <div className={buildClassName(styles.block, styles.block4)}>
              <p className={styles['office-address']}>
                {address}
              </p>
              <EmailPhoneNumberContacts phoneNumber={config.organization.phoneNumber} emailAddress={config.organization.email} />
              <div className={styles.socials}>
                <a href={config.organization.twitterUrl} target="_blank" rel="noreferrer"><TwitterIcon isExpTheme className={styles['social-x-icon']}/></a>
                <a href={config.organization.facebookUrl} target="_blank" rel="noreferrer"><FacebookIcon isExpTheme className={styles['social-x-icon']}/></a>
                <a href={config.organization.linkedinUrl} target="_blank" rel="noreferrer"><LinkedInIcon isExpTheme className={styles['social-x-icon']}/></a>
                <a href={config.organization.instagramUrl} target="_blank" rel="noreferrer"><InstagramIcon isExpTheme className={styles['social-ig-icon']}/></a>
                <a href={config.organization.youtubeUrl} target="_blank" rel="noreferrer"><YoutubeIcon className={styles['social-ig-icon']}/></a>
              </div>
            </div>
          </div>
        </div>
        {!isMobile && <div className={buildClassName(styles['footer-top-right'])}>
          <JoinSection joinUsLink={config?.footerColumns.joinUsLink} footerImageData={config.footerColumns.footerImageData}/>
        </div>}
      </div>
      {isMobile && <JoinSection joinUsLink={config?.footerColumns.joinUsLink} footerImageData={config.footerColumns.footerImageData}/>}
      <div className={styles['footer-bottom']}>
        <div className={styles['footer-big-text']}>
          <p>
              eXp REALTY
          </p>
        </div>
        { config.footerColumns.bottom.map((section, index) => {
          return (
            <p key={index}>
              {section.map(({ isAnchor, link, target, label }, innerIndex) => {
                return (
                  <span key={innerIndex}>
                    {
                      isAnchor ?
                        <a key={index} href={link} target={target}>{label}</a>
                        :
                        <>{label}</>
                    }
                  </span>
                );
              })}
            </p>
          );
        })}
        <ExpDynamicCopyright/>
      </div>
    </div>;
}

const ExpDynamicCopyright = () =>
  <p> © {new Date().getFullYear()} eXp Realty. eXp World Holdings, Inc. All Rights Reserved</p>;

const handleAccessibilityClick = () => {
  const element = document.getElementById('INDmenu-btn');
  if (element) element.click();
};

const EmailPhoneNumberContacts = ({ phoneNumber, emailAddress }: { phoneNumber: (isOnPreConPage?: boolean) => string; emailAddress: (isOnPreConPage?: boolean) => string; }) => {
  const phone = phoneNumber ? phoneNumber() : '';
  const email = emailAddress ? emailAddress() : '';

  return (
    <section className={styles.contact}>
      {phone && (
        <div className={styles['contact-item']}>
          <a href={`tel:${phone}`}>
            <PhoneIcon/>
            <p>
              {phone}
            </p>
          </a>
        </div>
      )}
      {email && (
        <div className={styles['contact-item']}>
          <a href={`mailto:${email}`}>
            <MailIcon/>
            <p>
              {email}
            </p>
          </a>
        </div>
      )}
    </section>
  );
};

const Column1 = ({ column1 }) => (
  <div className={styles['about-exp-links']}>
    {
      column1.map(({ isAnchor = false, link, target, label }, index) => {
        return (isAnchor ?
          <a key={`column1-${index}`} href={link} target={target}>{label}</a>
          :
          <Link key={`column1-${index}`} href={link} target={target}>{label}</Link>);
      })
    }
  </div>
);

const Column2 = ({ column2, showAccessibilityLink }) => (
  <div className={styles['about-exp-links']}>
    {
      column2.map(({ isAnchor, link, target, label }, index) => {
        if (showAccessibilityLink && label === 'Accessibility') {
          return (<a key={`column2-${index}`} onClick={handleAccessibilityClick}>Accessibility</a>);
        }
        return (isAnchor ?
          <a key={`column2-${index}`} href={link} target={target}>{label}</a>
          :
          <Link key={`column2-${index}`} href={link} target={target}>{label}</Link>);
      })
    }
    {showAccessibilityLink && <a onClick={handleAccessibilityClick}>Accessibility</a>}
  </div>
);

const Column3 = ({ column3 }) => (
  <div className={buildClassName(styles['about-exp-links'], styles['terms-and-conditions'])}>
    {
      column3.map(({ isAnchor, link, target, label }, index) => {
        return (isAnchor ?
          <a key={`column3-${index}`} href={link} target={target}>{label}</a>
          :
          <Link key={`column3-${index}`} href={link} target={target}>{label}</Link>);
      })
    }
  </div>
);

const JoinSection = ({ joinUsLink, footerImageData }) => (
  <div className={styles['join-section']}>
    <div className={styles['join-image-container']}>
      <ImageLoader src={footerImageData.src} alt={footerImageData.alt}/>
    </div>
    <Link href={joinUsLink} target='_blank' className={styles['join-link']}>
      <Button theme={SECONDARY_THEME} label={'Join Us'} className={styles['join-button']} />
    </Link>
  </div>
);

export default ExpThemedFooter;