export const EMBER_PAGE_URLS = [
  '/education-centre',
  '/education-centre/buying',
  '/education-centre/selling',
  '/company',
  '/company/our-story',
  '/company/covid-19',
  '/company/our-team',
  '/company/our-agents',
  '/terms-of-use',
  '/privacy-policy',
];

export const EMBER_META = {
  name: 'zoocasa-ember/config/environment',
  content: '%7B%22podModulePrefix%22%3A%22zoocasa-ember%2Fpods%22%2C%22modulePrefix%22%3A%22zoocasa-ember%22%2C%22environment%22%3A%22production%22%2C%22rootURL%22%3A%22%2F%22%2C%22locationType%22%3A%22auto%22%2C%22EmberENV%22%3A%7B%22FEATURES%22%3A%7B%7D%2C%22EXTEND_PROTOTYPES%22%3A%7B%22Date%22%3Afalse%7D%2C%22_APPLICATION_TEMPLATE_WRAPPER%22%3Afalse%2C%22_DEFAULT_ASYNC_OBSERVERS%22%3Atrue%2C%22_JQUERY_INTEGRATION%22%3Afalse%2C%22_TEMPLATE_ONLY_GLIMMER_COMPONENTS%22%3Atrue%7D%2C%22APP%22%3A%7B%22host%22%3A%22https%3A%2F%2Fwww.zoocasa.com%22%2C%22analytics%22%3A%7B%22enabled%22%3Afalse%7D%2C%22features%22%3A%7B%22soldData%22%3Atrue%2C%22travelTime%22%3Atrue%2C%22playground%22%3Afalse%2C%22drawOnMap%22%3Afalse%2C%22schoolsListPage%22%3Afalse%7D%2C%22map%22%3A%7B%22provider%22%3A%22apple%22%2C%22appleMapkitJWT%22%3A%22eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjVYM01CTjdWM0oifQ.eyJpc3MiOiI0MkFIQjVBMzY4IiwiaWF0IjoxNzEzODg4MTE1LCJleHAiOjE3NDUzNjY0MDB9.JHB5jRWdZIMvDvgjD2tDSR2rW4Nu6EQFepi8FzLG3b9GenX-wvB3uQHuUeBEHug3NWObNlU-VjQViZCgf5ecLA%22%7D%2C%22name%22%3A%22zoocasa-ember%22%2C%22version%22%3A%224.0.39%2Bdd9913c0%22%7D%2C%22fastboot%22%3A%7B%22hostWhitelist%22%3A%5B%7B%7D%2C%7B%7D%2C%7B%7D%2C%7B%7D%5D%7D%2C%22ember-cli-mirage%22%3A%7B%22usingProxy%22%3Afalse%2C%22useDefaultPassthroughs%22%3Atrue%7D%2C%22ember-component-css%22%3A%7B%22terseClassNames%22%3Atrue%7D%2C%22exportApplicationGlobal%22%3Afalse%7D',
} as const;

