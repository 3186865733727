import Router from 'next/router';
import configJSON from 'config.json';
import { camelizeKeys } from '@zoocasa/node-kit/objects/transform-keys';
import endpoint, { apiHeaders } from 'utils/endpoint';
import { Point, getListingById } from 'data/listing';
import { dasherize } from '@zoocasa/node-kit/strings/dasherize';
import getTargetedUrl from 'components/dynamic-page/new-area-listings-page/targeted-url-helper';
import { captureLastSearch } from 'pages/api/last-search/lastSearchApiClient';
import { formatQueryIntoURLQueryParams } from 'utils/listing-query-helper';
import defaultListingParams, { DEFAULT_LISTING_PARAMS_US } from 'contexts/preferences/listing-params/defaults';
import { findLocationByPlaceId } from 'utils/google-maps/geoLocator';
import { trackEvent } from 'utils/google-tag-manager';
import determineZoomLevel, { PROVINCE_STATE_ZOOM_LEVEL } from 'utils/determine-zoom-level';
import { SEARCH_PREDICTION_SOURCE_GOOGLE } from 'utils/google-maps/types';
import { HttpRequestMethodTypes } from 'types';
import { Position } from '@zoocasa/node-kit/map/types';
import { ThemeNames } from 'types/themes';
import { getGoSearchHost, isServerSide } from 'utils/host-config';

import { isCanadianProvinceCode, provinceOrStateCodeFromSlug } from 'utils/province_or_state';
import { LastSearchLocation } from 'contexts/preferences';
import { getPositionFromAddress } from 'utils/google-maps/api/geocoder';
import { SearchSuggestions } from 'components/suggested-location-dropdown';
import { getCoordinatesForLocation } from 'utils/user-location';

import type ListingParams from 'contexts/preferences/listing-params';
export type MapType = { changeLocation(location: Position, zoom: number): void };

export const LOCATION_ID_PREFIX = 'location-';

export const LISTINGS_SEARCH_PREDICTION_GROUP = 'listings';
export const LOCATIONS_SEARCH_PREDICTION_GROUP = 'locations';
export const SCHOOLS_SEARCH_PREDICTION_GROUP = 'schools';
export const BUILDINGS_SEARCH_PREDICTION_GROUP = 'buildings';
export type SearchPredictionGroup = typeof LISTINGS_SEARCH_PREDICTION_GROUP | typeof LOCATIONS_SEARCH_PREDICTION_GROUP | typeof SCHOOLS_SEARCH_PREDICTION_GROUP | typeof BUILDINGS_SEARCH_PREDICTION_GROUP;
export default class SearchPrediction {
  id: string;
  locationId: string | null;
  description: string;
  group: SearchPredictionGroup;
  label: string | null;
  matchedSubstrings: {
      length: number;
      offset: number;
  }[];
  data: {
      position: Point;
  } | null;
  source: string | null;
  identifier: number | null;

  constructor(searchPrediction: Record<string, unknown>) {
    const camelizedSearchPrediction = camelizeKeys(searchPrediction);
    const attributes = camelizedSearchPrediction.attributes as Record<string, unknown>;
    const relationships = camelizedSearchPrediction.relationships as Record<
      string,
      unknown
    >;
    const formattedSearchPrediction = {
      ...attributes,
      ...relationships,
      id: camelizedSearchPrediction.id,
    } as SearchPrediction;
    Object.assign(this, formattedSearchPrediction);
  }

  get path() {
    const { group } = this;
    if (group === LOCATIONS_SEARCH_PREDICTION_GROUP) {
      const [longitude, latitude] = this.data?.position ? this.data!.position.coordinates : [0, 0];
      return '/search?' + formatQueryIntoURLQueryParams({ latitude, longitude });
    } else {
      const typeOfPrediction = group.replace(`${group}-`, '').replace(/s$/, '');
      const slug = this.id.replace(/\s$/, '').replace(`${typeOfPrediction}-`, '');
      return `/${typeOfPrediction}/${slug}`;
    }
  }

  // Disabling this to prevent fetching client-app, but leave here for future references
  // get location() {
  //   const defaultPosition = [defaultListingParams.filter.longitude, defaultListingParams.filter.latitude];
  //   return endpoint<Record<string, any>>(`/services/api/v3/locations/${this.id.replace(LOCATION_ID_PREFIX, '')}`)
  //     .then(({ data }) => {
  //       if (data) {
  //         return data.attributes.position.coordinates;
  //       } else {
  //         return defaultPosition;
  //       }
  //     }).catch(() => {
  //       return getPositionFromAddress(this.id.replace(LOCATION_ID_PREFIX, '')).then(data => {
  //         if (data) {
  //           return [data.geometry.location.lng(), data.geometry.location.lat()];
  //         } else {
  //           return defaultPosition;
  //         }
  //       });
  //     });
  // }

  get slug() {
    let slug = '';
    const { group, id, description, source } = this;
    if (source === SEARCH_PREDICTION_SOURCE_GOOGLE) {
      const parts = description.split(',').map(part => part.trim().toLowerCase());
      slug = dasherize(parts.join(' '));
    } else if (group === 'locations') {
      slug = getLocationSlugFromId(id);
    }
    return slug;
  }

  setIdentifier = (identifier: number) => {
    this.identifier = identifier;
  };

  transitionToPath = async (
    searchPageQueryParams: ListingParams,
    sourceTheme: ThemeNames,
    map?: MapType,
    user?: any,
    addToRecentSearches?: (prediction: SearchPrediction) => void
  ) => {
    const { group, path } = this;
    trackEvent(group, 'search-results');
    if (group === LOCATIONS_SEARCH_PREDICTION_GROUP && searchPageQueryParams) {
      // Use existing coordinates if they're already set and valid
      let longitude = this.data?.position?.coordinates[0];
      let latitude = this.data?.position?.coordinates[1];
      const zoom = determineZoomLevel(this);
      let isUsingDefaultCoordinates = false;

      // Skip geocoding if we already have valid coordinates
      const hasValidCoordinates = typeof longitude === 'number' &&
                                  typeof latitude === 'number' &&
                                  !isNaN(longitude) && !isNaN(latitude) &&
                                  longitude !== 0 && latitude !== 0 &&
                                  longitude >= -180 && longitude <= 180 &&
                                  latitude >= -90 && latitude <= 90;

      if (!hasValidCoordinates) {
        // If we have a placeId from google maps, try to get coordinates from it
        if (this.locationId) {
          try {
            const location = await findLocationByPlaceId(this.locationId);
            if (location) {
              longitude = location.longitude;
              latitude = location.latitude;
            }
          } catch (error) {
            console.error('Error getting location by place ID:', error);
          }
        }

        // Try geocoding the address
        if (!longitude || !latitude) {
          try {
            const position = await getPositionFromAddress(this.description);
            if (position) {
              longitude = position.geometry.location.lng();
              latitude = position.geometry.location.lat();
              this.locationId = position.place_id;
            }
          } catch (error) {
            console.error('Error getting position from address:', error);
          }
        }

        // If we still don't have valid coordinates after all attempts, use defaults
        if (!latitude || !longitude) {
          isUsingDefaultCoordinates = true;
          // Get country code from the description if possible
          const description = this.description || '';
          if (description.includes('USA') || description.includes('United States') || description.includes('US')) {
            // US-based default
            latitude = DEFAULT_LISTING_PARAMS_US.filter.latitude;
            longitude = DEFAULT_LISTING_PARAMS_US.filter.longitude;
          } else {
            // Generic default
            latitude = defaultListingParams.filter.latitude;
            longitude = defaultListingParams.filter.longitude;
          }
        }

        // Update the data object with new coordinates
        this.data = {
          position: {
            coordinates: [longitude, latitude],
            type: 'Point',
            longitude,
            latitude,
          },
        };
      }

      if (typeof addToRecentSearches === 'function' && !isUsingDefaultCoordinates) {
        addToRecentSearches(this);
      }

      if (this.slug) {
        searchPageQueryParams.setSlug(this.slug);
      } else {
        searchPageQueryParams.setSlug('');
      }
      if (this.description) {
        searchPageQueryParams.setAreaName(this.description);
      }
      searchPageQueryParams.setZoom(zoom);
      searchPageQueryParams.setLatitude(latitude);
      searchPageQueryParams.setLongitude(longitude);

      // Determine which page user should go to
      if (map) { // if on map page, stay in map page
        // Update the listingParams with the new coordinates and zoom, and save them to the user's last search
        if (user?.id && searchPageQueryParams) {
          captureLastSearch({ userId: user.id, lastSearch: searchPageQueryParams, sourceTheme: sourceTheme });
        }
        map.changeLocation({ lng: longitude, lat: latitude }, zoom);
        return;
      } else { // if on other pages, determine if we should go to an area page or map page
        // If we received a province or state name from google, we will generate a custom slug for it
        const dasherizedDescription = dasherize(this.description.toLowerCase());
        const isProvinceOrState = provinceOrStateCodeFromSlug(dasherizedDescription);
        let customSlug;
        if (isProvinceOrState) customSlug = `${dasherizedDescription}-real-estate`;
        if (this.slug || customSlug) { // if location has a slug go to area page with existing filters
          let location = this.slug;
          let subLocation = '';
          if (this.label === 'neighbourhood') {
            const locationArr = this.description.split(', ');
            subLocation = '/' + dasherize(locationArr[0]);
            location = dasherize((locationArr[1] + ' ' + locationArr[2]).toLowerCase());
          }
          // For google we created custom slug for province or state
          const areaPageUrl = customSlug ? `${window.location.origin}/${customSlug}` : `${window.location.origin}/${location}-real-estate${subLocation}`;
          const areaPageUrlWithFilters = getTargetedUrl({ url: areaPageUrl, filters: searchPageQueryParams.filter, sort: searchPageQueryParams.sort, pageNumber: 1 });
          if (user?.id && searchPageQueryParams) {
            captureLastSearch({ userId: user.id, lastSearch: searchPageQueryParams, sourceTheme: sourceTheme });
          }
          Router.push(areaPageUrlWithFilters);
        } else { // if location does not have a slug go to search page with existing filters
          if (user?.id && searchPageQueryParams) {
            captureLastSearch({ userId: user.id, lastSearch: searchPageQueryParams, sourceTheme: sourceTheme });
          }
          Router.push('/search?' + formatQueryIntoURLQueryParams(searchPageQueryParams.filter) + '&sort=' + searchPageQueryParams.sort);
        }
      }
    } else if (group === LISTINGS_SEARCH_PREDICTION_GROUP) { // if search result is a listing
      const listingId = this.id.split('-')[1];
      const listing = await getListingById(listingId);
      Router.push(listing.addressPathWithFallback);
    } else if (group === SCHOOLS_SEARCH_PREDICTION_GROUP) { // if search result is a school
      window.location.href = `/school/${this.id.replace('school-', '')}`;
    } else if (group === BUILDINGS_SEARCH_PREDICTION_GROUP) { // if search result is a building
      Router.push(path);
    }
  };
}

export async function getSearchPredictions(params: Record<string, any>, useNewSearch = false) {
  try {

    let searchPredictionsUrl = `${getGoSearchHost(isServerSide())}/api/search-predictions`;
    let headers = {};

    if (!useNewSearch) { // Fallback to use client app search instead of go-search
      searchPredictionsUrl = `${isServerSide() ? configJSON.clientAppInternalHost : configJSON.host}/services/api/v3/search-predictions`;
      headers = apiHeaders();
    }

    const { data } = await endpoint<{ data: Record<string, unknown>[] }>(searchPredictionsUrl, HttpRequestMethodTypes.GET, params, headers);
    return data.map(d => new SearchPrediction(d));
  } catch {
    return null;
  }
}

export function createSearchPredictionModel(properties: SearchPrediction) {
  let data = null;
  if (properties.data) {
    data = { position: { ...properties.data.position, coordinates: [properties.data.position.coordinates[0], properties.data.position.coordinates[1]]}};
  }
  let matchedSubstrings: { length: number; offset: number }[] = [];
  if (properties.matchedSubstrings && properties.matchedSubstrings[0]) {
    matchedSubstrings = [{ length: properties.matchedSubstrings[0].length, offset: properties.matchedSubstrings[0].offset }];
  }

  return new SearchPrediction({
    attributes: {
      ...properties,
      data,
      matchedSubstrings,
    },
    id: properties.id,
  });
}

/**
 * Returns a slug parsed from a location id in a search prediction.
 *
 * NOTE: This can also determine whether this location has an area page.
 *
 * @param id the id of the search prediction {@link SearchPrediction.id}. Ids are of the format `location-<slug>`
 * @returns the slug parsed from the location id or undefined if no slug is found
 */
export function getLocationSlugFromId(id: string) { // This can also determine whether this location has an area page
  return id.split(LOCATION_ID_PREFIX)[1];
}

export function checkLocationHasAreaPageWithId(id: string) {
  return !!getLocationSlugFromId(id);
}

export type LastSearchUrlsResponse = {
  homesForSaleUrl: string;
  soldPricesUrl: string;
  mapViewUrl: string;
};

export type LastSearchSlugAndCoordsResponse = {
  slug?: string;
  latitude: number;
  longitude: number;
  subLocation?: string;
};

export const generateSlugAndCoordinatesFromLastSearch = async (lastSearch: LastSearchLocation): Promise<LastSearchSlugAndCoordsResponse> => {
  let lastSearchLatitude = lastSearch?.data?.position?.latitude || lastSearch?.data?.position?.coordinates[1];
  let lastSearchLongitude = lastSearch?.data?.position?.longitude || lastSearch?.data?.position?.coordinates[0];

  const hasAreaPage = checkLocationHasAreaPageWithId(lastSearch.id);
  const dasherizedDescription = dasherize(lastSearch.description);
  const isProvinceOrState = provinceOrStateCodeFromSlug(dasherizedDescription);

  if (isProvinceOrState) {
    return {
      slug: dasherizedDescription,
      latitude: lastSearchLatitude,
      longitude: lastSearchLongitude,
    };
  }
  else if (hasAreaPage) {
    let location = getLocationSlugFromId(lastSearch.id);
    let subLocation = '';
    if (lastSearch.label === 'neighbourhood') {
      const locationArr = lastSearch.description.split(', ');
      subLocation = '/' + dasherize(locationArr[0]);
      location = dasherize((locationArr[1] + ' ' + locationArr[2]).toLowerCase());
    }
    return {
      slug: location,
      latitude: lastSearchLatitude,
      longitude: lastSearchLongitude,
      subLocation: subLocation,
    };
  }
  else {
    if ((!lastSearchLongitude || !lastSearchLongitude) && lastSearch?.locationId) {
      const geoLocation = await findLocationByPlaceId(lastSearch.locationId);
      lastSearchLatitude = geoLocation?.latitude;
      lastSearchLongitude = geoLocation?.longitude;
    }
    return {
      latitude: lastSearchLatitude,
      longitude: lastSearchLongitude,
    };
  }
};

export const generateUrlsFromLastSearch = async (lastSearch: LastSearchLocation, hideImagelessListings?: boolean): Promise<LastSearchUrlsResponse> => {
  const slugAndCoordinates = await generateSlugAndCoordinatesFromLastSearch(lastSearch);
  const hasAreaPage = checkLocationHasAreaPageWithId(lastSearch.id);
  const dasherizedDescription = dasherize(lastSearch.description);
  const isProvinceOrState = provinceOrStateCodeFromSlug(dasherizedDescription);
  const imageQuery = hideImagelessListings ? '/filter?has-image=true' : '';

  let mapViewUrl = '/search';

  let latitude = slugAndCoordinates.latitude;
  let longitude = slugAndCoordinates.longitude;

  if (latitude && longitude) {
    // Default zoom based on location type
    let zoom = 13;
    if (lastSearch.label === 'city') {
      zoom = 12;
    } else if (lastSearch.label === 'neighbourhood') {
      zoom = 14;
    } else if (lastSearch.label === 'address') {
      zoom = 16;
    }

    // Include the areaName in the URL so the search input on map page will be correctly populated
    let areaName = lastSearch.description ? encodeURIComponent(lastSearch.description) : '';

    // Edge case where we have null island (0, 0) coordinates, set them to default values by country
    if (latitude === 0 && longitude === 0) {
      if (isCanadianProvinceCode(isProvinceOrState)) {
        latitude = defaultListingParams.filter.latitude;
        longitude = defaultListingParams.filter.longitude;
        areaName = defaultListingParams.filter.areaName;
      } else {
        latitude = DEFAULT_LISTING_PARAMS_US.filter.latitude;
        longitude = DEFAULT_LISTING_PARAMS_US.filter.longitude;
        areaName = DEFAULT_LISTING_PARAMS_US.filter.areaName;
      }
    }
    mapViewUrl = `/search?latitude=${latitude}&longitude=${longitude}&zoom=${zoom}&area-name=${areaName}`;
  }

  if (isProvinceOrState) {
    const updatedHomeUrl = `${slugAndCoordinates.slug}-real-estate`;
    return {
      homesForSaleUrl: updatedHomeUrl + imageQuery,
      soldPricesUrl: `${updatedHomeUrl}/sold` + imageQuery,
      mapViewUrl,
    };
  } else if (hasAreaPage) {
    const updatedHomeUrl = `/${slugAndCoordinates.slug}-real-estate${slugAndCoordinates.subLocation}`;
    return {
      homesForSaleUrl: updatedHomeUrl + imageQuery,
      soldPricesUrl: `${updatedHomeUrl}/sold` + imageQuery,
      mapViewUrl,
    };
  } else {
    return {
      homesForSaleUrl: '/search',
      soldPricesUrl: '/search',
      mapViewUrl,
    };
  }
};

export type ConvertedSearchSuggestion = Partial<SearchPrediction> | SearchSuggestions;

/**
 * Converts a search suggestion object to a search prediction object.
 *
 * @param {SearchSuggestions} suggestion - The search suggestion object to be converted.
 * @returns {ConvertedSearchSuggestion} The search prediction object created from the suggestion.
 *
 */
export async function convertSearchSuggestionToSearchPrediction(
  suggestion: SearchSuggestions
): Promise<ConvertedSearchSuggestion> {
  try {
    const { label = '', urlPath = '', latitude: suggestionLatitude, longitude: suggestionLongitude, identifier = 0 } = suggestion;

    const slug = urlPath.replace('-real-estate', '') || 'unknown-location';
    const id = `${LOCATION_ID_PREFIX}${slug}`;

    const [locationName = 'Unknown Location', locationType = 'city'] = label.split('- ') || [];
    const predictionLabel = locationType.toLowerCase().trim() || 'city';
    const predictionGroup = LOCATIONS_SEARCH_PREDICTION_GROUP;

    let longitude = defaultListingParams?.filter?.longitude;
    let latitude = defaultListingParams?.filter?.latitude;

    if (!suggestionLatitude || !suggestionLongitude) {
      [longitude, latitude] = await getCoordinatesForLocation(slug);
    } else {
      longitude = suggestionLongitude;
      latitude = suggestionLatitude;
    }
    return {
      identifier,
      description: locationName.trim(),
      group: predictionGroup,
      label: predictionLabel,
      id,
      locationId: id,
      data: {
        position: {
          longitude: longitude,
          latitude: latitude,
          coordinates: [longitude, latitude],
          type: 'Point',
        },
      },
      source: '',
      matchedSubstrings: [],
    };
  } catch (error) {
    console.error('Error in converting search suggestion:', error);
    return suggestion;
  }
}
