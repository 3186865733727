import configJSON from 'config.json';

export const isServerSide = () => (typeof window === 'undefined');

export const isClientSide = () => (typeof window !== 'undefined');

export const getInsightsHost = (isServerSide: boolean) => isServerSide ? configJSON.services.insights.serverSideHost : configJSON.services.insights.clientSideHost;

export const getGoSearchHost = (isServerSide: boolean) => isServerSide ? configJSON.services.search.serverSideHost : configJSON.services.search.clientSideHost;

export const getClientAppHost = (isServerSide: boolean) => isServerSide ? configJSON.clientAppInternalHost : configJSON.host;

export const getCmsHost = () => configJSON.cmsHost;

export const getProviderComplianceHost = () => configJSON.providerTermsOfUseHost;