export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function capitalizeWords(str: string): string {
  if (!str) return '';
  
  const lowerCaseExceptions = new Set(['in', 'on', 'is', 'to', 'for', 'width', 'and', 'by', 'a', 'at']);
  const uppercaseExceptions = new Set(['usa']);
  return str.split(' ').map(word => {
    // Handles hyphenated words
    if (word.includes('-')) {
      return word.split('-')
        .map(part => capitalize(part))
        .join('-');
    }

    const lowerWord = word.toLowerCase();
    if (lowerCaseExceptions.has(word)) {
      return lowerWord;
    }
    if (uppercaseExceptions.has(lowerWord)) {
      return word.toUpperCase();
    }
    return capitalize(word);
  }).join(' ');
}

export function capitalizeSentences(str: string) {
  return str.split('. ').map(word => capitalize(word)).join('. ');
}
