import configJSON from 'config.json';
import { capitalizeWords } from '@zoocasa/node-kit/strings/capitalize';
import { dasherize } from '@zoocasa/node-kit/strings/dasherize';
import { DEVICE_ID_COOKIE, SITE_LOCATION_COOKIE_NAME, USER_LOCATION_COOKIE_NAME } from 'constants/cookies';
import { defaultCACityPayload, defaultUSCityPayload } from 'constants/locations';
import defaultListingParams from 'contexts/preferences/listing-params/defaults';
import { CountryCode, CountryCodeList } from 'types/countries';
import endpoint from 'utils/endpoint';
import { getPositionFromAddress } from 'utils/google-maps/api/geocoder';
import { ProvinceOrState, provinceOrStateCodeFromName } from 'utils/province_or_state';
import { IUserLocation } from './types';
import isUserAgentCrawler, { isUserAgentLighthouse } from 'utils/crawler-agent';
import { USER_AGENT_HEADER_NAME } from 'constants/headers';
import { getObjectFromRequestCookie } from 'utils/cookies';
import { getStringFromRequestCookie } from 'utils/cookies';
import { ENVIRONMENT_DEVELOPMENT, isDevelopment, isEndToEndTest, isProduction, isStageTwo, isStaging, isVercel } from 'utils/environment';
import { getIpLocationApiPath, getUserLocationFromIp, IpLocationApiResponseType } from 'lib/pages/api/ip-location/IpLocationApiClient';
import { CF_CONNECTING_IP_HEADER_NAME,
  CF_IPCITY_HEADER_NAME,
  CF_IPCOUNTRY_HEADER_NAME,
  CF_IPLATITUDE_HEADER_NAME,
  CF_IPLONGITUDE_HEADER_NAME,
  CF_RAY_HEADER_NAME,
  CF_REGION_CODE_HEADER_NAME,
  CF_REGION_HEADER_NAME,
  X_KNOWN_BOT_HEADER_NAME,
  IncomingMessageType,
} from 'types/http';
import { getUserIpFromIpify } from '../../lib/pages/api/ip-location/ipify';
import { getClientIpFromXForwardedFor } from 'utils/ip';
import { ThemeName } from 'themes';
import { ThemeNames } from 'types/themes';
import { isTenantExpCA, isTenantExpUS, isTenantExpInService } from 'utils/tenant/which-tenant';

//#region Constants

export const DEFAULT_DEVICE_ID = 'unknown_device_id';

//#endregion

//#region Functions

/**
 * Retrieves the user location based on the incoming request and IP address.
 * 
 * This function is intended to be used only on the server side and follows a series of steps to determine the user location:
 * 
 * 1. **Default Location**: Sets a default user location based on the site location cookie, defaulting to either the US or CA city payload.
 * 2. **Immediate Return Conditions**: If the request is from a known bot, an API call, or during end-to-end testing, it returns the default location.
 * 3. **Stored Location**: Checks for a stored user location in cookies and returns it if available.
 * 4. **Development Environment**: In development, uses the ipify API to determine the user location.
 * 5. **Cloudflare Headers**: In staging, production, or stage-two environments, attempts to extract location from Cloudflare headers.
 * 6. **IP and Device ID**: If not in development, determines the client IP and device ID to fetch the location using an internal IP location API.
 * 7. **Fallback**: If all else fails, defaults to the city payload based on the site location.
 *
 * @param request The incoming HTTP request object.
 * @returns The user location payload or a default location if determination fails.
 */
export async function getUserLocationFromServerSideRequest(request?: IncomingMessageType, deviceId?: string) {
  let isUsaSiteLocation = false;
  let userLocation: IUserLocation | undefined;

  if (request) {
    const userAgent = request?.headers?.[USER_AGENT_HEADER_NAME];
    const isKnownBot = Boolean(request?.headers?.[X_KNOWN_BOT_HEADER_NAME]);
    const isApiCall = request.url?.includes('/api/'); // Are we calling our own API?

    // If it test environment or is crawler, return the default user location
    const shouldReturnDefaultLocation = isEndToEndTest || isKnownBot || isApiCall || isUserAgentCrawler(request?.headers) || isUserAgentLighthouse(userAgent);

    // Get the site location from the cookie
    const siteLocation = getStringFromRequestCookie(SITE_LOCATION_COOKIE_NAME, request) as CountryCode;
    if (siteLocation === CountryCodeList.UNITED_STATES) {
      isUsaSiteLocation = true;
    }

    // Set default userLocation
    userLocation = isUsaSiteLocation ? defaultUSCityPayload : defaultCACityPayload;
    
    if (shouldReturnDefaultLocation) {
      return userLocation;
    }

    // Check for stored user location in the cookies
    const storedUserLocation = getObjectFromRequestCookie<IUserLocation>(USER_LOCATION_COOKIE_NAME, request);
    if (storedUserLocation) {
      return storedUserLocation;
    }

    const getApiPath = (userIp: string, deviceId: string) => {
      const host = isVercel ? configJSON.vercelHost : configJSON.host;
      return `${host}${getIpLocationApiPath(userIp, deviceId)}`;
    };

    if (isDevelopment) { // If we are in development, we use the ipify API to get the user location since we cant rely on cloudflare or vercel headers to be present
      const userIpFromIpify = await getUserIpFromIpify();
      if (userIpFromIpify) {
        const response: IpLocationApiResponseType = await getUserLocationFromIp(userIpFromIpify, ENVIRONMENT_DEVELOPMENT, getApiPath);
        if (!('error' in response)) {
          userLocation = response;
        }
      }
      return userLocation;
    }

    // If we are running on staging, production or stage-two, fetch the user location from cloudflare headers
    if (isStaging || isProduction || isStageTwo) {
      try {
        const cloudflareLocation = getUserLocationFromCloudflareHeaders(request.headers);
        if (cloudflareLocation) {
          return cloudflareLocation;
        }
      } catch (error: any) {
        if (Boolean(process.env.ENABLE_IP_LOCATION_LOG) || isDevelopment) {
          console.warn(`Failed to parse user location from cloudflare', ${error?.message || error}`);
        }
      }
    }

    // If we got here means that we failed to parse the cloudflare data or that we are not in
    // our k8s environments (production, staging or stage-two). If we are in development, we don't waste
    // time calling the ip location API in development we will fallback to the default location
    if (isDevelopment) {
      userLocation = isUsaSiteLocation ? defaultUSCityPayload : defaultCACityPayload;
    }

    // If we are not in development, we need to determine the user ip address and the user device
    // id before calling our ip location API

    // If we are in vercel, we will use the `x-forwarded-for` header to get the client ip address, we will
    // use the `cf-connecting-ip` header otherwise. If we can't get the client ip address, we will fallback
    // to the default location based on the site location.
    let clientIp: string | undefined;
    if (isVercel) {
      clientIp = getClientIpFromXForwardedFor(request);
    } else {
      clientIp = request.headers[CF_CONNECTING_IP_HEADER_NAME];
    }

    if (!clientIp) {
      console.warn('Failed to parse client ip address');
      return isUsaSiteLocation ? defaultUSCityPayload : defaultCACityPayload;
    }

    // Get the device id from the `device-id` cookie, if we can't get it from the cookie, we will try to
    // use the `cf-ray` header instead. If we can't get it from the cookie or the `cf-ray` header, we will
    // fallback to DEFAULT_DEVICE_ID
    const deviceId = getStringFromRequestCookie(DEVICE_ID_COOKIE, request, request.headers[CF_RAY_HEADER_NAME] || DEFAULT_DEVICE_ID);

    // Use our own ip location API to get the user location based on the client ip address and device id
    try {
      const response: IpLocationApiResponseType = await getUserLocationFromIp(clientIp, deviceId, getApiPath);
      if (!('error' in response)) {
        userLocation = response;
      } else {
        console.warn(`Failed to fetch user location from ip location API, clientIp: ${clientIp}, deviceId: ${deviceId}, reason: ${response.reason}, statusCode: ${response.statusCode}`);
      }
    } catch (error: any) {
      console.warn(`Failed to fetch user location from ip location API, clientIp: ${clientIp}, deviceId: ${deviceId}, reason: ${error?.message || error}`);
    }
  }

  // If we failed to fetch the user location, we will fallback to the default location based on the site location
  if (!userLocation) {
    userLocation = (isUsaSiteLocation ? defaultUSCityPayload : defaultCACityPayload);
  }

  // Return user location or fallback based on the site location
  return userLocation;
}

/**
 * Determines and returns a supported user location based on the provided theme name and user location.
 *
 * @param themeName - The name of the theme, which indicates the tenant (e.g., ZOOCASA).
 * @param inputUserLocation - An optional user location object containing country code and other location details.
 * @returns The supported user location. If the input user location is not from a supported country,
 *          it defaults to a Canadian or US city payload based on the tenant.
 */
export const getSupportedUserLocation = (themeName: ThemeName, inputUserLocation?: IUserLocation): IUserLocation => {
  // TODO: We do have a configurable list of supported countries for each tenant (see https://github.com/zoocasa/zoocasa-next/blob/development/src/tenants/definitions/exp-commercial.tenant.ts#L381)
  // We should use that instead
  const isCanadianTenant = themeName === ThemeNames.ZOOCASA || isTenantExpCA(themeName);
  const isUsTenant = isTenantExpUS(themeName) || isTenantExpInService(themeName);
  const isSupportedCountry = inputUserLocation.countryCode == CountryCodeList.CANADA || inputUserLocation.countryCode == CountryCodeList.UNITED_STATES;
  if (!isSupportedCountry) {
    if (isCanadianTenant) return defaultCACityPayload;
    if (isUsTenant) return defaultUSCityPayload;
    return defaultCACityPayload;
  }
  return inputUserLocation;
};

/**
 * Fetches the geographical coordinates (longitude, latitude) for a given location slug.
 * The function first attempts to retrieve the coordinates from an API endpoint.
 * If the API call fails or no data is returned, it falls back to an address-based lookup
 * using the `getPositionFromAddress` function. If both attempts fail, it returns a default position.
 * 
 * @param {string} slug - The unique identifier (slug) of the location.
 * @returns {Promise<number[]>} A promise that resolves to an array containing the [longitude, latitude]
 *                              of the location, or the default coordinates if not found.
 */
export async function getCoordinatesForLocation(slug: string): Promise<number[]> {
  const defaultPosition = [defaultListingParams.filter.longitude, defaultListingParams.filter.latitude];
  return endpoint<Record<string, any>>(`/services/api/v3/locations/${slug}`)
    .then(({ data }) => {
      if (data) {
        return data.attributes.position.coordinates;
      } else {
        return defaultPosition;
      }
    }).catch(() => {
      return getPositionFromAddress(slug).then(data => {
        if (data) {
          return [data.geometry.location.lng(), data.geometry.location.lat()];
        } else {
          return defaultPosition;
        }
      });
    });
}

//#endregion

//#region Private Functions

/**
 * Extracts and validates user location data from Cloudflare headers.
 *
 * This function reads several headers provided by Cloudflare to determine the user's location.
 * The headers include:
 * - {@link CF_IPCOUNTRY_HEADER_NAME}: Represents the country code of the user's IP address.
 * - {@link CF_IPCITY_HEADER_NAME}: Represents the city associated with the user's IP address.
 * - {@link CF_REGION_HEADER_NAME}: Represents the region or state associated with the user's IP address.
 * - {@link CF_REGION_CODE_HEADER_NAME}: Represents the region code, which may not always be provided.
 * - {@link CF_IPLATITUDE_HEADER_NAME}: Represents the latitude of the user's location.
 * - {@link CF_IPLONGITUDE_HEADER_NAME}: Represents the longitude of the user's location.
 * - {@link CF_CONNECTING_IP_HEADER_NAME}: Represents the user's IP address.
 *
 * Validation Process:
 * 1. **Region Code Fallback**: If the `region_code` is not provided, it attempts to derive it from the `region` name.
 * 2. **Field Completeness**: Checks if all required fields (`country_code`, `city`, `region_code`, `latitude`, `longitude`) are present.
 *    - If any of these fields are missing, the function logs a warning (if logging is enabled) and returns `undefined`.
 * 3. **Country Code Validation**: Ensures the `country_code` is either {@link CountryCodeList.CANADA} or {@link CountryCodeList.UNITED_STATES}.
 *    - If the `country_code` is invalid or unsupported, the function logs a warning (if logging is enabled) and returns `undefined`.
 *
 * If all validations pass, the function constructs and returns an `IUserLocation` object containing the user's location data.
 *
 * @param headers The headers from a Cloudflare request. See {@link CloudflareIncomingMessage} for more details.
 *
 * @returns The user {@link IUserLocation} object if valid headers are provided, otherwise `undefined`.
 */
function getUserLocationFromCloudflareHeaders(headers: IncomingMessageType['headers']): IUserLocation | undefined {
  const country_code = headers[CF_IPCOUNTRY_HEADER_NAME];
  const city = headers[CF_IPCITY_HEADER_NAME];
  const region = headers[CF_REGION_HEADER_NAME];
  let region_code = headers[CF_REGION_CODE_HEADER_NAME];
  const latitude = headers[CF_IPLATITUDE_HEADER_NAME];
  const longitude = headers[CF_IPLONGITUDE_HEADER_NAME];
  const ip = headers[CF_CONNECTING_IP_HEADER_NAME];

  // Sometimes the region code is not provided, so we try to get it from the region name
  if (!region_code && region) {
    const normalizedRegion = capitalizeWords(region.trim().toLowerCase()) as ProvinceOrState;
    region_code = provinceOrStateCodeFromName(normalizedRegion);
  }

  // If the required fields cannot be determined from the cloudflare headers, return undefined
  if (!country_code || !city || !region_code || !latitude || !longitude) {
    if (Boolean(process.env.ENABLE_IP_LOCATION_LOG)) {
      const missingFields = [];
      if (!country_code) missingFields.push('country_code');
      if (!city) missingFields.push('city');
      if (!region_code) missingFields.push('region_code');
      if (!latitude) missingFields.push('latitude');
      if (!longitude) missingFields.push('longitude');

      console.warn(`Incomplete user location data received from cloudflare. Missing properties: ${missingFields.join(', ')}, IP: ${ip}, agent: ${headers[USER_AGENT_HEADER_NAME]}`); 
    }
    return undefined;
  }
  
  // If the country code is invalid or not supported, return undefined
  if (![CountryCodeList.CANADA, CountryCodeList.UNITED_STATES].includes(country_code.toUpperCase() as CountryCode)) {
    if (Boolean(process.env.ENABLE_IP_LOCATION_LOG)) {
      console.warn(`Unsupported or invalid country code received from cloudflare. Country code: ${country_code}, IP: ${ip}, agent: ${headers[USER_AGENT_HEADER_NAME]}`);
    }
    return undefined;
  }

  // If the required fields are valid, return the user location
  const userLocation: IUserLocation = {
    name: `${city}, ${region_code}`,
    slug: `${dasherize(city.toLowerCase())}-${region_code.toLowerCase()}`,
    latitude: parseFloat(latitude),
    longitude: parseFloat(longitude),
    countryCode: country_code.toUpperCase() as CountryCode,
    ip,
  };
  return userLocation;
}

//#endregion
